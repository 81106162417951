import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue2Filters from "vue2-filters";
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
const moment = require("moment");
require("moment/locale/fr");

import VTimelinePro from 'vue-timeline-pro';

Vue.use(VTimelinePro);

Vue.use(BootstrapVue);

Vue.use(require("vue-moment"), {
  moment,
});

var Vue2FiltersConfig = {
  number: {
    format: "0,0",
    thousandsSeparator: " ",
    decimalSeparator: ".",
  },
};
Vue.use(Vue2Filters, Vue2FiltersConfig);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  mixins: [Vue2Filters.mixin],
  render: h => h(App)
}).$mount('#app')
