<template>
  <div id="app">
    <router-view />
    <Spinner
      class="loading"
      v-if="loading"
      :size="55"
      :line-size="7"
      line-fg-color="#C5C5C5"
      :speed="0.6"
    ></Spinner>
  </div>
</template>
<script>
import Spinner from 'vue-simple-spinner'
import { mapState } from 'vuex'
export default {
  components: {
    Spinner,
  },
  computed: {
    ...mapState(['loading']),
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:wght@100&family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');
#app {
  font-family: "Rethink Sans", 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.loading {
  position: fixed;
  z-index: 998;
  height: 5rem;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
