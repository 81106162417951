import axios from 'axios'
import store from '../store'
import * as Sentry from '@sentry/browser'

export default () => {
  let ApiPublic = axios.create({
    baseURL: 'https://api.notif.immo',
    headers: {
      PlatformOrigin: 'melo',
      'Content-Type': 'application/ld+json',
    },
  })
  ApiPublic.interceptors.request.use((config) => {
    if (!config.url.includes('location_autocomplete'))
      store.commit('UPDATE_LOADING', true)
    return config
  })
  ApiPublic.interceptors.response.use(
    (response) => {
      store.commit('UPDATE_LOADING', false)
      return response
    },
    (error) => {
      store.commit('UPDATE_LOADING', false)
      if (error.response.status === 400 || error.response.status === 401) {
        if (error.response.data.message)
          Sentry.setExtra('errorMessage', error.response.data.message.message)
        if (error.response.data['hydra:description'])
          Sentry.setExtra(
            'errorMessage',
            error.response.data['hydra:description'],
          )
      }
      return Promise.reject(error)
    },
  )
  return ApiPublic
}
