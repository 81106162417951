import Vue from "vue";
import Vuex from "vuex";
import ApiPublic from '@/api/apiPublic'
import { createStore } from "vuex-extensions";
Vue.use(Vuex);

const store = createStore(Vuex.Store, {
  state: {
    currentDisplayAd: null,
    publicCustomization: null,
    loading: false
  },
  mutations: {
    SET_DISPLAY_AD(state, ad) {
      state.currentDisplayAd = ad
    },
    SET_PUBLIC_CUSTOMIZATION(state, value) {
      state.publicCustomization = value
    },
    UPDATE_LOADING(state, value) {
      state.loading = value
    },
  },
  actions: {
    async fetchPublicCustomization({ commit }, uuid) {
      return ApiPublic()
        .get('/public/customizations/' + uuid)
        .then((res) => {
          if (res.data) commit('SET_PUBLIC_CUSTOMIZATION', res.data)
        })
    },
    async fetchAdDisplay({ commit }, payload) {
      return ApiPublic()
        .get(
          '/public/match?token=' +
            payload.token +
            '&customization=' +
            payload.customization,
        )
        .then((res) => {
          if (res.data.uuid) commit('SET_DISPLAY_AD', res.data)
          //else router.push({ name: 'expired' })
        })
    },
  },
  getters: {
    formatDate: (state) => (date) => {
      return Vue.moment(date).fromNow()
    },
    thousandSeparator: (state) => (amount) => {
      if (
        amount !== "" ||
        amount !== undefined ||
        amount !== 0 ||
        amount !== "0" ||
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }
    },
    getUrl: (state) => {
      // get adverts with urls that are not null
      let disclosedAdverts = state.currentDisplayAd.adverts.filter(advert => advert.url)
      // get adverts that are not expired
      let activeAdverts = disclosedAdverts.filter(advert => advert.expired === false)
      // if there are some => get the most recent crawled advert
      if (activeAdverts && activeAdverts.length) {
        return activeAdverts.sort((a,b) => new Date(b.lastCrawledAt).getTime() - new Date(a.lastCrawledAt).getTime())[0].url;
      } else {        
         // if not get the most recent crawled advert overall
        return disclosedAdverts && disclosedAdverts.length ? disclosedAdverts.sort((a,b) => new Date(b.lastCrawledAt).getTime() - new Date(a.lastCrawledAt).getTime())[0].url : null
      }      
    },
    cleansedEvents: (state, getters) => {
      let notExpired = state.currentDisplayAd.adverts.filter(advert => advert.expired == false)
      let events = notExpired.map(advert => advert.events).flat()
      let uniqueEvents = [...new Map(events.map(event => [event['fieldNewValue'] + event[
        'fieldName'], event])).values()]
      let intEvents = uniqueEvents.filter(el => el.percentVariation) 

      if (intEvents && intEvents.length) {
        let timeline = intEvents.map(obj => {
          let tmp = {}
          if (obj.fieldName == 'surface') {
            tmp.icon = require('../assets/icons/area-rounded.svg')
            tmp.description = obj.fieldOldValue + ' m² → ' + obj.fieldNewValue + ' m²'
          }
          if (obj.fieldName == 'price') {
            tmp.icon = require('../assets/icons/price-rounded.svg')
            tmp.description = getters.thousandSeparator(obj.fieldOldValue) + ' € → ' +
              getters.thousandSeparator(obj.fieldNewValue) + ' €'
          }

          let properties = {
            icon: tmp.icon,
            title: obj.percentVariation > 0 ? '+' + obj.percentVariation + ' %' : obj
              .percentVariation + ' %',
            description: tmp.description,
            date: new Date(obj.createdAt),
          };
          return properties;
        });
        const sortedTimeline = timeline.sort((a, b) => b.date - a.date)
        return sortedTimeline
      }
    },
    cleansedContacts: (state) => {
      const contacts = state.currentDisplayAd.adverts.map(advert => ({
        ...advert.contact,
        updatedAt: advert.updatedAt,
        publisherType: advert.publisher.type
      }));
      const uniqueContacts = []

      function splitWords(str) {
        return str.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, "").split(/\s+/);
      }

      function isAgencySimilar(agency1, agency2) {
        const words1 = new Set(splitWords(agency1));
        const words2 = new Set(splitWords(agency2));

        const commonWords = new Set([...words1].filter(word => words2.has(word)));
        const totalWords = new Set([...words1, ...words2]);

        // Calculate similarity (e.g., proportion of common words)
        const similarity = commonWords.size / totalWords.size;
        return similarity >= 0.1; // You can adjust this threshold as needed
      }

      function countNonNullProperties(contact) {
        return Object.values(contact).filter(value => value !== null && value !== undefined).length;
      }

      contacts.forEach(contact => {
        if (!contact.agency) return; // Skip if agency is null or undefined

        const existingIndex = uniqueContacts.findIndex(uniqueContact =>
          uniqueContact.agency && isAgencySimilar(uniqueContact.agency, contact.agency)
        );

        if (existingIndex !== -1) {
          // Compare the richness of the contact object
          if (countNonNullProperties(contact) > countNonNullProperties(uniqueContacts[existingIndex])) {
            uniqueContacts[existingIndex] = contact;
          }
        } else {
          uniqueContacts.push(contact);
        }
      });


      let noContact = contacts.filter(contact => contact.email || contact.phone || contact
        .agency || contact.name)

      let uniquesNoContact = [...new Map(noContact.map(contact => [contact['name'] + contact[
        'agency'], contact])).values()]
      if (uniqueContacts && uniqueContacts.length) {
        return uniqueContacts
      } else {
        return uniquesNoContact
      }
    },
  }
})
export default store;
